import {
  FieldValues,
  Path,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import { formatPhoneNumber } from "utils";
import { pattern } from "utils/validation";

import { FieldText } from "./FieldText";

interface FieldPhoneProps<T extends FieldValues> {
  title?: string;
  register: UseFormRegister<T>;
  setValue: UseFormSetValue<T>;
  name: Path<T>;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
}

export const FieldPhone = <T extends FieldValues>({
  title,
  register,
  setValue,
  name,
  defaultValue,
  disabled,
  placeholder,
}: FieldPhoneProps<T>) => {
  const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    setValue(name, formattedNumber as any, { shouldValidate: true });
  };

  return (
    <FieldText
      title={title || "Phone Number*"}
      type="text"
      {...register(name, {
        minLength: 6,
        maxLength: 12,
        required: "Phone number is required",
        pattern: {
          value: pattern.phone,
          message: "Invalid phone number format",
        },
        onChange: onPhoneChange,
      })}
      defaultValue={defaultValue}
      placeholder={placeholder || "Enter Phone Number"}
      disabled={disabled}
    />
  );
};
