import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Button, FieldText, Modal, TextError } from "components/shared";

import { AWAITING, showErrorToast, showSuccessToast } from "utils";

import { NegotiationService } from "api/client";

type MoveToLostProps = {
  contractID: string;
  onRefresh?: () => void;
  status: string;
};

type FormProps = {
  notes: string;
};

export const MoveToLost = ({
  contractID,
  onRefresh,
  status,
}: MoveToLostProps) => {
  const [showModal, setShowModal] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>();

  const { mutate, isLoading } = useMutation(
    (comment: string) =>
      NegotiationService.negotiationOffer(contractID, {
        offer_type: "declined",
        comment,
      }),
    {
      onSuccess: () => {
        showSuccessToast("Saved Successfully");
        onRefresh?.();
        setShowModal(false);
      },
      onError: () => {
        showErrorToast("Failed to Save");
      },
    }
  );

  const onSubmit = (data: FormProps) => {
    mutate(data?.notes);
  };

  if (status !== AWAITING.key) {
    return null;
  }

  return (
    <>
      <div
        className="ml-2 text-secondary text-sm cursor-pointer font-medium underline"
        onClick={() => setShowModal(true)}
      >
        Move To Lost
      </div>
      <Modal
        open={showModal}
        size="auto"
        title="Move Vehicle to Lost"
        closeModal={() => setShowModal(false)}
        description="You're about to mark this vehicle as lost. Please provide a reason
            for tracking purposes."
      >
        <div className="w-[300px]">
          <div className="valuation-add__txt">
            <Controller
              render={({ field: { value, onChange } }) => (
                <FieldText
                  className="h-10 outline-none pl-7 rounded-4 w-full pt-1 text-txt bg-sidebarbg"
                  placeholder="Enter Your Notes Here"
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              name="notes"
              rules={{
                required: true,
              }}
            />
            {errors.notes && <TextError error="Notes are required" />}
          </div>
          <div className="w-100 mx-auto mt-5">
            <Button
              type="button"
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
            >
              <span className="font-bold uppercase">Confirm</span>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
