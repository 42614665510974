import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";

import { BuyerSummary } from "pages/MotorMarket";
import { BidsHistory } from "pages/MotorMarket/Summary/BidsHistory";
import { TenderBuyerVehicleSummary } from "pages/Tender";
import { RemoveFromTender } from "pages/Tender/Seller/RemoveFromTender";

import {
  DealershipInformation,
  DealInformation,
  Footer,
  LoadVehicleSummary,
  NotifyUpdate,
  SaleDetailSection,
  SellerInformation,
  SummaryHeader,
  ValuationsSection,
  VehicleInformation,
} from "components/app";
import { DeclineRequest } from "components/app/Summary/DeclineRequest";
import { BidSection } from "components/app/Tender/BidSection";
import { RemoveFromMotorMarket } from "components/MotorMarket/RemoveFromMotorMarket";
import { Banner } from "components/shared";
import { Timer } from "components/Timer";
import { VehicleComments } from "components/VehicleNotes";
import { VehiclePhotoContainer } from "components/VehiclePhotoContainer";

import { AuthContext } from "context/AuthContext";

import {
  ACCEPTED,
  ACQUIRING_STATUSES,
  AWAITING,
  FLOW_TYPE,
  formatDateToJsDate,
  MOTOR_MARKET_AUCTION,
  SOLD_COMBINED_STATUSES,
  TENDERED,
  INSPECTION_STARTED,
  ACQUIRED,
} from "utils";
import { getPageTitle } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IVehicleSummary } from "types";
import { IMotorMarket } from "types/IMotorMarket";

import { VehicleService } from "api/client";

import { SummaryNavigationLink } from "./SummaryNavigationLink";

type StateProps = {
  isAuctionBuy: boolean;
  vehicle?: IVehicleSummary;
  title?: string;
};

export const SearchSummary = () => {
  const params = useParams();
  const contractID = params?.id;
  const { hasPermission } = useContext(AuthContext);

  const { state } = useLocation();

  const vehicle = (state as StateProps)?.vehicle;
  const title = (state as StateProps)?.title;
  const isAuctionBuy =
    vehicle?.status.name === MOTOR_MARKET_AUCTION.key &&
    !vehicle.is_contract_owner;
  const isTenderBuy =
    vehicle?.status.name === TENDERED.key && !vehicle.is_contract_owner;

  const { data, refetch, isLoading } = useQuery(
    [
      `fetch_search_global_summary`,
      contractID,
      { with: "seller,buyer,owner,assessments" },
    ],
    VehicleService.fetchGlobalSearchDetails,
    {
      enabled: !!contractID && !isAuctionBuy,
    }
  );

  const vehicleData = data?.data;
  const status = vehicleData?.status?.name;
  const isAuctionBuyFromSummary =
    vehicleData?.auction_vehicle?.id && !vehicleData?.is_contract_owner;
  const isTenderBuyFromSummary =
    vehicleData?.tender_vehicle?.id && !vehicleData?.is_contract_owner;
  const isStatusSold = status?.includes("sold");

  const flow = data?.data.retailed
    ? FLOW_TYPE.RETAIL_DEALERSHIP
    : FLOW_TYPE.WHOLESALE_DEALERSHIP;

  const canRevertVehicle = hasPermission(
    flow === FLOW_TYPE.RETAIL_DEALERSHIP
      ? PermissionCategory.RETAIL_INSPECTIONS
      : PermissionCategory.WHOLESALE_INSPECTIONS,
    PermissionAction.REVERT
  );

  if (isAuctionBuy || isAuctionBuyFromSummary) {
    return (
      <BuyerSummary
        vehicle={{
          ...(vehicle as unknown as IMotorMarket),
          ...(vehicleData?.auction_vehicle?.status && {
            status: {
              ...vehicleData.auction_vehicle.status,
            },
          }),
        }}
        isSearch
      />
    );
  }

  if (
    (isTenderBuy && vehicleData?.tender_vehicle?.tender_id) ||
    isTenderBuyFromSummary
  ) {
    return (
      <TenderBuyerVehicleSummary
        tenderID={
          vehicleData?.tender_vehicle?.tender_id ||
          vehicleData?.tender_vehicle?.tender_group_id
        }
        vehicleID={
          vehicleData?.tender_vehicle?.tender_vehicle_id ||
          vehicleData?.tender_vehicle?.id
        }
      />
    );
  }

  if (isLoading) {
    return <LoadVehicleSummary />;
  }

  return (
    <div>
      <Helmet>
        <title>{getPageTitle("Summary")}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="dashboard__header">
          <SummaryNavigationLink
            title={title || ""}
            isRetail={vehicleData?.retailed || false}
            isGlobalSearch={!!vehicle?.contract_id}
          />
        </div>
        <div className="dashboard__content">
          <div className="dashboard__content--left">
            <SummaryHeader
              data={(data?.data as IVehicleSummary) || {}}
              onRefresh={refetch}
              flow={flow}
            />
            {status &&
              ([...ACQUIRING_STATUSES, ...SOLD_COMBINED_STATUSES].includes(
                status
              ) ||
                status === MOTOR_MARKET_AUCTION.key) && (
                <DealInformation
                  data={vehicleData || ({} as IVehicleSummary)}
                  defaultOpen
                  onRefresh={refetch}
                  auctionID={vehicleData?.auction_vehicle_id}
                  flow={flow}
                />
              )}
            <VehicleInformation
              data={(vehicleData as IVehicleSummary) || {}}
              defaultOpen
              flow={FLOW_TYPE.RETAIL_DEALERSHIP}
              refetch={refetch}
              isLocked={false}
            />
            {(vehicleData?.requester?.dealership?.id ||
              (vehicleData?.seller?.id &&
                vehicleData.acquisitions?.acquisition?.amount)) && (
              <DealershipInformation
                data={vehicleData || ({} as IVehicleSummary)}
              />
            )}
            <SellerInformation
              data={vehicleData || ({} as IVehicleSummary)}
              flow={flow}
            />
            <VehiclePhotoContainer
              data={vehicleData as IVehicleSummary}
              refetch={refetch}
            />
          </div>
          <div className="dashboard__content--right">
            <Banner
              name={vehicleData?.status?.name || ""}
              label={vehicleData?.status?.label || ""}
            />
            {status === ACQUIRED.key && (
              <Footer
                data={data?.data || ({} as IVehicleSummary)}
                flow={FLOW_TYPE.RETAIL_DEALERSHIP}
                onRefresh={refetch}
              />
            )}
            {isStatusSold && canRevertVehicle && data?.data && (
              <SaleDetailSection data={data?.data} refetch={refetch} />
            )}
            <div className="right-container">
              {![
                MOTOR_MARKET_AUCTION.key,
                TENDERED.key,
                INSPECTION_STARTED.key,
              ].includes(data?.data?.status?.name || "") && (
                <ValuationsSection
                  data={data?.data || ({} as IVehicleSummary)}
                  onRefresh={refetch}
                  isGlobalSearch
                />
              )}
              {status !== ACQUIRED.key && (
                <Footer
                  data={data?.data || ({} as IVehicleSummary)}
                  flow={FLOW_TYPE.RETAIL_DEALERSHIP}
                  onRefresh={refetch}
                />
              )}
              {[AWAITING.key, ACCEPTED.key].includes(status as string) &&
                data && (
                  <NotifyUpdate
                    contract_id={data?.data.contract_id}
                    hidden={data?.data?.updates_notified_to_valuers}
                    refetch={refetch}
                  />
                )}
              <DeclineRequest
                contractID={contractID || ""}
                requestID={vehicleData?.request_id || ""}
                onRefresh={refetch}
                status={data?.data?.status?.name || ""}
              />
              {data?.data?.status?.name === MOTOR_MARKET_AUCTION.key && (
                <div>
                  {!!vehicleData?.auction?.ends_at && (
                    <Timer
                      targetDate={formatDateToJsDate(
                        vehicleData?.auction?.ends_at
                      )}
                      isSummaryView
                    />
                  )}
                  <BidsHistory
                    vehicleAuctionID={vehicleData?.auction_vehicle_id}
                    selectedVehicle={vehicleData as IVehicleSummary}
                    panelView
                    refetchSummary={refetch}
                  />
                </div>
              )}
              {data?.data?.status?.name === MOTOR_MARKET_AUCTION.key && (
                <RemoveFromMotorMarket
                  auctionVehicleId={vehicleData?.auction_vehicle_id || ""}
                  onRefresh={refetch}
                />
              )}
              {data?.data?.status?.name === TENDERED.key && (
                <>
                  <BidSection
                    tenderStatus={
                      vehicleData?.tender_vehicle?.status?.name || ""
                    }
                    tenderVehicleId={
                      data?.data.tender_vehicle?.tender_vehicle_id || ""
                    }
                  />
                  <RemoveFromTender
                    contractID={vehicleData?.contract_id || ""}
                    tenderID={
                      data?.data.tender_vehicle?.tender_vehicle_id || ""
                    }
                  />
                </>
              )}
              <VehicleComments contractID={vehicleData?.contract_id || ""} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
