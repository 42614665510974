import { useMutation, useQuery } from "@tanstack/react-query";
import { useState, useMemo, ChangeEvent } from "react";

import { Button, ButtonLink, Pagination } from "components/shared";

import {
  DEFAULT_ITEMS_PER_PAGE,
  showErrorToast,
  showSuccessToast,
} from "utils";

import { ITenderVehicle } from "types";

import { TenderService } from "api/client";

import { CardTenderVehicleSelect } from "../Card";
import { SearchBar } from "../Field";
import { LoadItem } from "../LoadVehicle";

interface ModalTenderAddVehiclesProps {
  tenderId: string;
  onAdded: () => void;
  dealershipID: string;
}

export const ModalTenderAddVehicles = ({
  tenderId,
  onAdded,
  dealershipID,
}: ModalTenderAddVehiclesProps) => {
  const [searchTxt, setSearchTxt] = useState("");
  const [selectedVehicles, setSelectedVehicles] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading: isListLoading } = useQuery(
    [
      "fetchAcquiredVehicles",
      {
        with: "owner,seller,dealership",
        dealership_id: dealershipID,
        page: currentPage,
        search: searchTxt,
      },
    ],
    TenderService.getAcquiredVehicles
  );

  const vehicles = data?.data;

  const { mutate, isLoading } = useMutation(
    (data: any) => TenderService.addVehicleToTender(tenderId, data),
    {
      onSuccess: () => {
        onAdded();
      },
      onError: () => {
        showErrorToast("Failed to Save");
      },
    }
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTxt(e.target.value);
    setCurrentPage(1);
  };

  const handleToggleSelect = (vehicle: ITenderVehicle) => {
    const { contract_id } = vehicle;
    selectedVehicles.includes(contract_id)
      ? setSelectedVehicles((vehicles) =>
          vehicles.filter((id) => id !== contract_id)
        )
      : setSelectedVehicles((vehicles) => [...vehicles, contract_id]);
  };

  const filteredVehicles = useMemo(() => {
    return vehicles || [];
  }, [vehicles]);

  const handleSelectAll = () => {
    filteredVehicles?.length === selectedVehicles.length
      ? setSelectedVehicles([])
      : setSelectedVehicles([
          ...(filteredVehicles?.map((vehicle) => vehicle.contract_id) || []),
        ]);
  };

  const handleAddVehicles = () => {
    mutate(
      { contract_ids: selectedVehicles },
      { onSuccess: () => showSuccessToast("Successfully Added") }
    );
  };

  const onNextPage = () => {
    setCurrentPage((current) => current + 1);
    setSelectedVehicles([]);
  };

  const onPrevPage = () => {
    setCurrentPage((current) => current - 1);
    setSelectedVehicles([]);
  };

  const onIndexedPage = (pageIndex: number) => {
    setCurrentPage(pageIndex);
    setSelectedVehicles([]);
  };

  return (
    <div className="flex flex-col h-maxModalHeight min-w-lg">
      <p className="text-xl font-bold text-txt leading-10 text-center">
        Add Vehicles
      </p>
      <div className="w-full flex flex-row-reverse gap-11 mb-6">
        <div className="w-72 relative">
          <SearchBar onSearch={handleSearch} />
        </div>
        <ButtonLink onClick={handleSelectAll}>
          <span
            className={
              filteredVehicles.length
                ? "text-secondary"
                : "text-primary cursor-default"
            }
          >
            {filteredVehicles.length &&
            filteredVehicles.length === selectedVehicles.length
              ? "Deselect All"
              : "Select All"}
          </span>
        </ButtonLink>
      </div>
      <Pagination
        currentPage={currentPage}
        pageLimit={DEFAULT_ITEMS_PER_PAGE}
        total={data?.meta?.total || 0}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onIndexedPage={onIndexedPage}
      />
      <div className="w-full flex-1 overflow-auto mb-5 min-w-1230">
        {isListLoading ? (
          <LoadItem />
        ) : (
          <div className="w-full flex-1 flex flex-col gap-4">
            {filteredVehicles?.length ? (
              filteredVehicles.map((vehicle) => (
                <CardTenderVehicleSelect
                  key={vehicle.contract_id}
                  data={vehicle}
                  selected={selectedVehicles.includes(vehicle.contract_id)}
                  onClick={() => handleToggleSelect(vehicle)}
                />
              ))
            ) : (
              <div className="dashboard__content--empty">
                No Vehicles Available to Add.
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex justify-center">
        <div className="w-150">
          <Button
            className="btn-confirm uppercase font-semibold"
            onClick={handleAddVehicles}
            isLoading={isLoading}
            disabled={!filteredVehicles.length || !selectedVehicles.length}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};
