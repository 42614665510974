import { useMutation } from "@tanstack/react-query";
import classNames from "classnames";
import { ChangeEvent, useMemo, useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";

import { ModalDeleteCar } from "components/app";
import { Modal, SvgImage } from "components/shared";

import { showErrorToast } from "utils";

import { IImage, ISection, OptimisticImage } from "types";

import AddMorePlaceholder from "assets/images/placeholder/AddMorePlaceholderImage.svg";

import { VehicleService } from "api/client";

import { useMultipleUpload } from "hooks";

import { ImageLoader } from "./ImageLoader";
import { ImagePreLoad } from "./ImagePreLoad";

interface VehiclePhotoProps {
  contractID: string;
  section: ISection;
  isLocked?: boolean;
  images: IImage[];
  onChange: (isUploading: boolean) => void;
  onRefresh: () => void;
  onCarousel: (val: number) => void;
  placeholders: ISection[];
  onDeleteImage: (id: string) => void;
  addOptimisticImages: (images: OptimisticImage[]) => void;
}

export const VehiclePhoto = ({
  section,
  contractID,
  isLocked,
  images,
  onRefresh,
  onCarousel,
  onChange,
  placeholders,
  onDeleteImage,
  addOptimisticImages,
}: VehiclePhotoProps) => {
  const [showDeleteCarModal, setShowDeleteCarModal] = useState(false);
  const fileInputField = useRef<HTMLInputElement>(null);
  const [previewImage, setPreviewImage] = useState("");

  const { mutate } = useMutation(
    (id: string) => VehicleService.removeImage({ image_id: id }, contractID),
    {
      onSuccess: () => {
        onRefresh();
      },
      onError: () => {
        showErrorToast("Something went wrong");
      },
    }
  );

  const imgIndex = useMemo(() => {
    return images?.findIndex((img) => img.id === section.id);
  }, [images, section]);

  const { onMultipleUpload } = useMultipleUpload({
    contractID: contractID,
    section: section,
    onRefresh: onRefresh,
    placeholders: placeholders,
    addOptimisticImages: addOptimisticImages,
  });

  const onImageUpload = () => {
    if (!isLocked && fileInputField.current) {
      fileInputField.current.click();
    }
  };

  const handleRemoveFile = () => {
    if (!isLocked) {
      if (images[imgIndex]) {
        onDeleteImage(images[imgIndex].id);
        mutate(images[imgIndex].id);
        setShowDeleteCarModal(false);
      } else {
        showErrorToast("Image uploading. Please wait a moment and try again");
      }
    }
  };

  const handleDeleteCarModal = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    setShowDeleteCarModal(true);
  };

  if (section.label === "Add More") {
    return (
      <div
        className="text-center h-82 flex items-center justify-center"
        onClick={onImageUpload}
      >
        <div className="relative flex items-center justify-center flex-col h-full overflow-hidden">
          <ImageLoader
            src={AddMorePlaceholder}
            style={{ width: 52, height: 37 }}
            alt="Placeholder"
          />
          <span className="text-waikawaGrey text-10 mt-1">{section.label}</span>
        </div>
        <input
          type="file"
          multiple
          ref={fileInputField}
          onChange={onMultipleUpload}
          className="hidden"
          accept="image/*"
        />
      </div>
    );
  }

  if (imgIndex > -1 && previewImage) {
    setPreviewImage("");
  }

  if (imgIndex > -1 || previewImage) {
    return (
      <Draggable
        key={imgIndex}
        draggableId={imgIndex.toString()}
        index={imgIndex}
        isDragDisabled={isLocked}
      >
        {(provided, snapshot) => (
          <div
            className="h-82 flex items-center justify-center z-10"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div
              className={classNames(
                "relative flex items-center justify-center flex-col h-full overflow-hidden rounded-lg",
                {
                  "border-2 border-primary": snapshot.isDragging,
                }
              )}
              onClick={() => onCarousel(imgIndex)}
            >
              <ImagePreLoad
                className="object-cover"
                src={images[imgIndex] ? images[imgIndex].url : previewImage}
                alt="Car Img"
              />
              {!isLocked && (
                <div className="absolute flex right-3 bottom-3 z-10">
                  <button onClick={handleDeleteCarModal}>
                    <SvgImage name="DeleteIcon" width={25} />
                  </button>
                </div>
              )}
              <Modal
                size="auto"
                open={showDeleteCarModal}
                closeModal={() => setShowDeleteCarModal(false)}
              >
                <ModalDeleteCar
                  onDelete={handleRemoveFile}
                  onCancel={() => setShowDeleteCarModal(false)}
                />
              </Modal>
            </div>
          </div>
        )}
      </Draggable>
    );
  }

  if (section.isOptimistic) {
    return (
      <div className="h-82 flex items-center justify-center text-center">
        <div className="flex items-center justify-center flex-col h-full overflow-hidden">
          <ImageLoader
            className="object-cover"
            src={section.placeholder}
            alt="Placeholder"
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className="h-82 flex items-center justify-center text-center"
      onClick={onImageUpload}
    >
      <div className="flex items-center justify-center flex-col h-full overflow-hidden">
        <ImageLoader
          src={section.placeholder}
          style={{ width: 52, height: 37 }}
          alt="Placeholder"
        />
        <span className="text-waikawaGrey text-10 mt-1">{section.label}</span>
      </div>
      <input
        type="file"
        multiple
        ref={fileInputField}
        onChange={async (event: ChangeEvent<HTMLInputElement>) => {
          onChange(true);
          onMultipleUpload(event).finally(() => onChange(false));
        }}
        className="hidden"
        accept="image/*"
      />
    </div>
  );
};
