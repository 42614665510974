import { Dialog, Transition } from "@headlessui/react";
import { useState, useRef, useEffect, useMemo, Fragment } from "react";
import { Navigation, Pagination, Zoom, Lazy, Keyboard } from "swiper";
import { Swiper, SwiperSlide, SwiperRef } from "swiper/react";

import { SvgImage } from "components/shared";

import { IImage } from "types";

import { useViewportSizing } from "hooks";

import "./styles.scss";
import { modalCarouselCarTestID } from "../dataTestIDs";
import { ImageRotate } from "./ImageRotate/ImageRotate";

export interface ModalCarouselCarProps {
  open: boolean;
  closeModal: () => void;
  images: IImage[];
  activeIndex?: number;
  dataTestID?: string;
  contractID?: string;
  onRefresh?: () => void;
}

export const ModalCarouselCar = ({
  images,
  open,
  activeIndex = 0,
  closeModal,
  dataTestID,
  contractID,
  onRefresh,
}: ModalCarouselCarProps) => {
  const ref = useRef<SwiperRef>(null);
  const { isDesktop, isTablet } = useViewportSizing();
  const [selectedIndex, setSelectedIndex] = useState<number>(activeIndex);
  const [rotationAngles, setRotationAngles] = useState<{
    [key: number]: number;
  }>({});

  useEffect(() => {
    setSelectedIndex(activeIndex);
  }, [activeIndex]);

  const carouselCloseIcon = useMemo(() => {
    if (isDesktop) {
      return "CarouselCloseIcon";
    } else if (isTablet) {
      return "CarouselCloseSmallIcon";
    }
    return "CarouselCloseBlackIcon";
  }, [isDesktop, isTablet]);

  const onSuccess = () => {
    onRefresh?.();
    closeModal();
    setRotationAngles({});
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="carousel-modal"
        onClose={closeModal}
        data-testid={dataTestID || modalCarouselCarTestID}
      >
        <div className="min-h-screen lg:px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="carousel-modal__overlay" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="carousel-modal__container w-responsive">
              <Swiper
                ref={ref}
                initialSlide={selectedIndex}
                className="vehicleSwiper"
                zoom={true}
                navigation={true}
                lazy={true}
                loop={true}
                pagination={{ clickable: true }}
                keyboard={{ enabled: true }}
                centeredSlides={true}
                modules={[Zoom, Navigation, Pagination, Lazy, Keyboard]}
                slidesPerView={1}
                onSlideChange={(swiper) => {
                  setSelectedIndex(swiper.realIndex);
                }}
                onSwiper={(swiper) => {
                  setSelectedIndex(swiper.realIndex);
                }}
              >
                {images?.map((image: IImage, index) => {
                  const url = image.url ? image.url : image.thumb;
                  return (
                    <SwiperSlide key={image.id}>
                      <div className="swiper-zoom-container">
                        <img
                          alt={image.label}
                          src={url}
                          className="swiper-lazy"
                          style={{
                            transform: `rotate(${
                              rotationAngles[index] || 0
                            }deg)`,
                            transition: "transform 0.3s ease-in-out",
                          }}
                        />
                        <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div className="flex gap-6 absolute top-0 right-[10px] z-10 px-5">
                <ImageRotate
                  contractID={contractID || ""}
                  images={images}
                  swiperRef={ref}
                  rotations={rotationAngles}
                  onRotation={setRotationAngles}
                  onRefresh={onSuccess}
                />
                <button
                  type="button"
                  onClick={() => {
                    ref.current?.swiper.zoom.in();
                  }}
                  tabIndex={-1}
                >
                  <SvgImage name="MagnifyingGlassPlusIcon" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    ref.current?.swiper.zoom.out();
                  }}
                  tabIndex={-1}
                >
                  <SvgImage name="MagnifyingGlassMinusIcon" />
                </button>
                <button type="button" onClick={closeModal}>
                  <SvgImage name={carouselCloseIcon} />
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
