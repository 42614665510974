import classNames from "classnames";
import { useState, useEffect } from "react";

import { LoadingSpinner } from "components/Loading/LoadingSpinner";

interface ImagePreLoadProps {
  src?: string;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
}

export const ImagePreLoad = ({
  src,
  alt,
  className,
  style,
}: ImagePreLoadProps) => {
  const [loading, setLoading] = useState(true);
  const [loadedSrc, setLoadedSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!src) {
      setLoading(false);
      setLoadedSrc(undefined);
      return;
    }

    setLoading(true);
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = src;
    setLoadedSrc(src);

    img.onload = () => {
      setLoading(false);
      setLoadedSrc(src);
    };

    img.onerror = () => setLoading(false);
  }, [src]);

  return (
    <>
      {loading && (
        <div className="absolute">
          <LoadingSpinner />
        </div>
      )}
      <img
        src={loadedSrc}
        alt={alt}
        className={classNames(className, { "opacity-0": loading })}
        style={style}
      />
    </>
  );
};
