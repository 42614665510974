import { useMutation } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useState } from "react";
import { SwiperRef } from "swiper/react";

import { Button, SvgImage } from "components/shared";

import { showErrorToast, showSuccessToast } from "utils";

import { IImage } from "types";

import { VehicleService } from "api/client";

interface ImageRotateProps {
  images: IImage[];
  contractID: string;
  swiperRef: React.RefObject<SwiperRef>;
  rotations: {
    [key: number]: number;
  };
  onRotation: React.Dispatch<
    React.SetStateAction<{
      [key: number]: number;
    }>
  >;
  onRefresh?: () => void;
}

export const ImageRotate = ({
  images,
  contractID,
  swiperRef,
  onRotation,
  rotations,
  onRefresh,
}: ImageRotateProps) => {
  const { mutate, isLoading } = useMutation(
    (formData: FormData) => VehicleService.updateImage(formData, contractID),
    {
      onSuccess: () => {
        showSuccessToast("Successfully updated");
        onRefresh?.();
      },
      onError: () => {
        showErrorToast("Failed to update");
      },
    }
  );

  const currentIndex =
    ((swiperRef?.current?.swiper?.activeIndex || 0) - 1) % images.length;

  const imageRotate = () => {
    onRotation((prev) => ({
      ...prev,
      [currentIndex]: (prev[currentIndex] || 0) + 90,
    }));
  };

  const getRotatedImageBlob = async (
    imageElement: HTMLImageElement,
    rotation: number
  ): Promise<Blob | null> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = imageElement.src;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          console.error("Canvas context is null!");
          resolve(null);
          return;
        }

        const imgWidth = img.naturalWidth;
        const imgHeight = img.naturalHeight;

        if (rotation % 180 !== 0) {
          canvas.width = imgHeight;
          canvas.height = imgWidth;
        } else {
          canvas.width = imgWidth;
          canvas.height = imgHeight;
        }

        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((rotation * Math.PI) / 180);
        ctx.drawImage(img, -imgWidth / 2, -imgHeight / 2);

        // Convert to Blob
        canvas.toBlob((blob) => {
          if (!blob) {
            console.error("Failed to generate image blob.");
            resolve(null);
            return;
          }

          resolve(
            new File([blob], `rotated_image.jpg`, { type: "image/jpeg" })
          );
        }, "image/jpeg");
      };

      img.onerror = () => {
        console.error("Failed to load image.");
        resolve(null);
      };
    });
  };

  const save = async () => {
    if (!swiperRef.current?.swiper) return;

    const slides: HTMLDivElement[] = Array.from(
      swiperRef.current?.swiper?.slides || []
    ) as HTMLDivElement[];

    const activeSlide: HTMLDivElement | undefined = slides.find(
      (slide: HTMLDivElement) => slide.classList.contains("swiper-slide-active")
    );

    if (!activeSlide) return;
    const imgElement = activeSlide?.querySelector("img") as HTMLImageElement;

    if (imgElement) {
      const rotationAngle = rotations[currentIndex] || 0;
      const rotatedBlob = await getRotatedImageBlob(imgElement, rotationAngle);
      if (!rotatedBlob) return;

      const imageSelected = images[currentIndex];
      const formData = new FormData();
      formData.append("image", rotatedBlob);
      formData.append("category", imageSelected.category);
      formData.append("label", imageSelected.label);
      formData.append("image_id", imageSelected.id);

      mutate(formData);
    }
  };

  if (!contractID) return null;

  return (
    <>
      <button
        type="button"
        onClick={imageRotate}
        className="mt-[-6px] mr-[-8px]"
      >
        <SvgImage name="ImageRotate" />
      </button>
      <Button
        className={classNames(
          "!bg-[#272626] z-10 px-8 p-1 rounded-3xl !w-[100px]",
          { "!text-label": !rotations[currentIndex] }
        )}
        isLoading={isLoading}
        onClick={save}
        disabled={!rotations[currentIndex]}
      >
        Save
      </Button>
    </>
  );
};
