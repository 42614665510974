import { useContext, useState } from "react";

import { SvgImage } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { FieldValuation } from "../Field/FieldValuation";

type Reference = {
  car_sales?: string;
  red_book?: string;
};

type ResearchVehicleProps = {
  reference?: Reference;
};

export const ResearchVehicle = ({ reference }: ResearchVehicleProps) => {
  const [showResearchVehicle, setShowResearchVehicle] = useState(false);
  const { hasPermission } = useContext(AuthContext);

  const { car_sales, red_book } = reference || {};

  if (
    !hasPermission(PermissionCategory.PRICE_GUIDE, PermissionAction.VIEW_ANY)
  ) {
    return null;
  }

  return (
    <div>
      <div onClick={() => setShowResearchVehicle((prev) => !prev)}>
        <FieldValuation
          title="Research Vehicle"
          tool={
            <SvgImage
              name="SelectDown"
              className={`${showResearchVehicle ? "transform rotate-180" : ""}`}
            />
          }
        />
      </div>
      {showResearchVehicle && (
        <div className="!mt-2">
          {car_sales && (
            <a
              className="section__body--link"
              target="_blank"
              href={car_sales}
              rel="noreferrer"
            >
              carsales.com.au
            </a>
          )}
          {red_book && (
            <a
              className="section__body--link"
              target="_blank"
              href={red_book}
              rel="noreferrer"
            >
              redbook.com.au
            </a>
          )}
        </div>
      )}
    </div>
  );
};
