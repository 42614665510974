import { ValuationLog } from "components/app/Summary/ValuationLog";
import { Modal } from "components/shared";

import { IValuations } from "types";
import "../styles.scss";

export interface ModalTradeAmountLogProps {
  open: boolean;
  onClose: () => void;
  data?: IValuations["verification"];
}

export const ModalTradeAmountLog = ({
  open,
  onClose,
  data,
}: ModalTradeAmountLogProps) => {
  const trade = data?.trade;

  return (
    <Modal
      title="Trade Amount Log"
      open={open}
      size="auto"
      closeModal={onClose}
    >
      <div className="offer">
        <ValuationLog
          key={1}
          name={trade?.valuer?.name || ""}
          price={trade?.amount.toString() || ""}
          role=""
          date={trade?.created_at || ""}
          status="valued"
        />
      </div>
    </Modal>
  );
};
