import AddCancelIcon from "assets/images/add-cancel-icon.svg";
import AddConfirmIcon from "assets/images/add-confirm-icon.svg";
import AddDisableIcon from "assets/images/add-disable-icon.svg";
import AddIcon from "assets/images/add-icon.svg";
import ArrowLeftIcon from "assets/images/arrow-left.svg";
import ArrowRightIcon from "assets/images/arrow-right.svg";
import BackIcon from "assets/images/back-icon.svg";
import BackToTopIcon from "assets/images/back-to-top.svg";
import CalendarIcon from "assets/images/calendar.svg";
import CameraIcon from "assets/images/camera.png";
import CarIcon from "assets/images/car-icon.svg";
import CarouselCloseBlackIcon from "assets/images/carousel-close-black.svg";
import CarouselCloseSmallIcon from "assets/images/carousel-close-small.svg";
import CarouselCloseIcon from "assets/images/carousel-close.svg";
import CheckIcon from "assets/images/check-icon.svg";
import ChevronLeftBlackIcon from "assets/images/chevron-left-black.svg";
import ChevronLeftSmallIcon from "assets/images/chevron-left-small.svg";
import ChevronLeftIcon from "assets/images/chevron-left.svg";
import ChevronRightBlackIcon from "assets/images/chevron-right-black.svg";
import ChevronRightSmallIcon from "assets/images/chevron-right-small.svg";
import ChevronRightIcon from "assets/images/chevron-right.svg";
import ChevronThinIcon from "assets/images/chevron-thin.svg";
import ChevronUpDisabledIcon from "assets/images/chevron-up-disabled.svg";
import ChevronUpIcon from "assets/images/chevron-up.svg";
import ClockIcon from "assets/images/clock-icon.svg";
import CloseCircleIcon from "assets/images/close-circle.svg";
import CloseIcon from "assets/images/close-icon.svg";
import ConfirmIcon from "assets/images/confirm_icon.svg";
import CosmeticPhotoIcon from "assets/images/cosmetic-photo.svg";
import CreateIcon from "assets/images/create.svg";
import CustomerBGIcon from "assets/images/customer-bg.svg";
import CustomerCompleteIcon from "assets/images/customer-complete-logo.svg";
import CustomerLogoIcon from "assets/images/customer-logo.svg";
import Danger from "assets/images/danger.svg";
import DealershipIcon from "assets/images/dealership-icon.svg";
import DeleteIcon from "assets/images/delete-icon.svg";
import DownloadIcon from "assets/images/download.svg";
import EditPen from "assets/images/edit.svg";
import EyeIcon from "assets/images/eye-icon.svg";
import EyeShowIcon from "assets/images/eye-show-icon.svg";
import FailedIcon from "assets/images/failed.svg";
import FileIcon from "assets/images/File.svg";
import ImageRotate from "assets/images/image-rotate.svg";
import InfoIcon from "assets/images/info-icon.svg";
import LeftRoundedIcon from "assets/images/left-rounded.svg";
import LicenseIcon from "assets/images/license-icon.svg";
import LinkIconDisabled from "assets/images/link-icon-disabled.svg";
import LinkIcon from "assets/images/link-icon.svg";
import LogoMd from "assets/images/logo-md.svg";
import LogoMobile from "assets/images/logo-mobile.svg";
import MPLogo from "assets/images/logo.png";
import Logo from "assets/images/logo.svg";
import MagnifyingGlassMinusIcon from "assets/images/magnifying-glass-minus.svg";
import MagnifyingGlassPlusIcon from "assets/images/magnifying-glass-plus.svg";
import ManualSearchIcon from "assets/images/manual-search-icon.svg";
import MapIcon from "assets/images/map.svg";
import MarketInfoIcon from "assets/images/market-info-icon.svg";
import MarketStarIconFilled from "assets/images/market-star-filled.svg";
import MarketStartIconOutlined from "assets/images/market-star-outline.svg";
import Acquisition from "assets/images/menu-icons/acquisition.svg";
import AvatarIcon from "assets/images/menu-icons/avatar.svg";
import LogoutIcon from "assets/images/menu-icons/logout.svg";
import MenuIcon from "assets/images/menu.svg";
import NoImageSvg from "assets/images/noImage.svg";
import OdometerKmsIcon from "assets/images/odometer-kms.svg";
import PendingIcon from "assets/images/pending-icon.svg";
import PhotosIcon from "assets/images/Photos.svg";
import PictureCloseIcon from "assets/images/picture-close-icon.svg";
import AddMorePlaceholderImage from "assets/images/placeholder/AddMorePlaceholderImage.svg";
import passenger from "assets/images/placeholder/Back-placeholder.svg";
import BackPlaceholderImage from "assets/images/placeholder/BackPlaceholderImage.svg";
import BuildPlate from "assets/images/placeholder/Build Plate-placeholder.svg";
import BuildPlatePlaceholderImage from "assets/images/placeholder/BuildPlatePlaceholderImage.svg";
import CenterDashboardImage from "assets/images/placeholder/CenterDashboardImage.svg";
import compliance from "assets/images/placeholder/Compliance Plate-placeholder.svg";
import CompliancePlatePlaceholderImage from "assets/images/placeholder/CompliancePlatePlaceholderImage.svg";
import ControlPanelOnImage from "assets/images/placeholder/ControlPanelOnImage.svg";
import dashboard from "assets/images/placeholder/Dashboard-placeholder.svg";
import DashboardPlaceholderImage from "assets/images/placeholder/DashboardPlaceholderImage.svg";
import DriverSidePlaceholderImage from "assets/images/placeholder/DriverSidePlaceholderImage.svg";
import front from "assets/images/placeholder/Front-placeholder.svg";
import FrontPlaceholderImage from "assets/images/placeholder/FrontPlaceholderImage.svg";
import interior from "assets/images/placeholder/Interior-placeholder.svg";
import InteriorPlaceholderImage from "assets/images/placeholder/InteriorPlaceholderImage.svg";
import driver from "assets/images/placeholder/Left-placeholder.svg";
import LeftPlaceholderImage from "assets/images/placeholder/LeftPlaceholderImage.svg";
import odometer from "assets/images/placeholder/Odometer-placeholder.svg";
import OdometerReadingPlaceholderImage from "assets/images/placeholder/OdometerReadingPlaceholderImage.svg";
import rear from "assets/images/placeholder/Right-placeholder.svg";
import TopPlaceholderImage from "assets/images/placeholder/TopPlaceholderImage.svg";
import UnionPlaceholderImage from "assets/images/placeholder/UnionPlaceholderImage.svg";
import PlusDark from "assets/images/plus-dark.svg";
import PlusIcon from "assets/images/plus.svg";
import RefreshIcon from "assets/images/refresh.svg";
import RequestIcon from "assets/images/request-icon.svg";
import RingIcon from "assets/images/ring.svg";
import ScanDesktopIcon from "assets/images/scan-desktop-icon.svg";
import ScanMobileIcon from "assets/images/scan-mobile-icon.svg";
import SearchIcon from "assets/images/search.svg";
import SelectDown from "assets/images/select-down.svg";
import SuccessIcon from "assets/images/success-icon.svg";
import SupportHero from "assets/images/support-hero.svg";
import Support from "assets/images/support.svg";
import TakePictureIcon from "assets/images/take-picture.svg";
import TickCircleIcon from "assets/images/tick-circle.svg";
import ToastClose from "assets/images/toast-close.svg";
import ToastSuccess from "assets/images/toast-success.svg";
import TrashIcon from "assets/images/trash-icon.svg";
import ValuationSentIcon from "assets/images/valuation-sent.svg";
import ValuedImg from "assets/images/valued-img.png";
import VehicleConfirmationIcon from "assets/images/vehicle-confirmation.svg";
import VinLocationIcon from "assets/images/vin-location-icon.svg";
import WarningIcon from "assets/images/warning-icon.svg";
import Warning from "assets/images/warning.svg";
import WhiteEyeIcon from "assets/images/white-eye-icon.svg";

import { svgImageTestID } from "../dataTestIDs";

const IMAGES: any = {
  Acquisition,
  AddIcon,
  AddDisableIcon,
  AddConfirmIcon,
  AddCancelIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  AvatarIcon,
  BackIcon,
  BackToTopIcon,
  CameraIcon,
  CarIcon,
  CheckIcon,
  ChevronUpIcon,
  ChevronUpDisabledIcon,
  ChevronLeftIcon,
  ChevronLeftSmallIcon,
  ChevronLeftBlackIcon,
  ChevronRightIcon,
  ChevronRightSmallIcon,
  ChevronRightBlackIcon,
  ChevronThinIcon,
  CarouselCloseIcon,
  CarouselCloseSmallIcon,
  CarouselCloseBlackIcon,
  CloseIcon,
  ClockIcon,
  CreateIcon,
  ConfirmIcon,
  CosmeticPhotoIcon,
  CustomerCompleteIcon,
  CustomerLogoIcon,
  CustomerBGIcon,
  Danger,
  DeleteIcon,
  DealershipIcon,
  EditPen,
  EyeIcon,
  EyeShowIcon,
  FailedIcon,
  FileIcon,
  LeftRoundedIcon,
  LicenseIcon,
  LinkIcon,
  LinkIconDisabled,
  Logo,
  LogoMd,
  LogoMobile,
  LogoutIcon,
  ManualSearchIcon,
  MenuIcon,
  NoImageSvg,
  PendingIcon,
  PictureCloseIcon,
  RequestIcon,
  RingIcon,
  PhotosIcon,
  PlusDark,
  PlusIcon,
  ValuedImg,
  WhiteEyeIcon,
  ScanDesktopIcon,
  ScanMobileIcon,
  SearchIcon,
  SelectDown,
  TickCircleIcon,
  ToastClose,
  ValuationSentIcon,
  VehicleConfirmationIcon,
  VinLocationIcon,
  ToastSuccess,
  TrashIcon,
  TakePictureIcon,
  SuccessIcon,
  InfoIcon,
  MarketInfoIcon,
  MarketStarIconFilled,
  MarketStartIconOutlined,
  // Photo section placeholders
  front,
  compliance,
  interior,
  odometer,
  dashboard,
  passenger,
  driver,
  rear,
  OdometerKmsIcon,
  AddMorePlaceholderImage,
  BackPlaceholderImage,
  BuildPlatePlaceholderImage,
  CompliancePlatePlaceholderImage,
  DashboardPlaceholderImage,
  DriverSidePlaceholderImage,
  FrontPlaceholderImage,
  InteriorPlaceholderImage,
  LeftPlaceholderImage,
  OdometerReadingPlaceholderImage,
  TopPlaceholderImage,
  UnionPlaceholderImage,
  ControlPanelOnImage,
  CenterDashboardImage,
  MagnifyingGlassPlusIcon,
  MagnifyingGlassMinusIcon,
  "build-plate": BuildPlate,
  MPLogo,
  Support,
  SupportHero,
  DownloadIcon,
  RefreshIcon,
  Warning,
  WarningIcon,
  CloseCircleIcon,
  CalendarIcon,
  MapIcon,
  ImageRotate,
};
export interface SvgImageProps {
  name: string;
  width?: number;
  height?: number;
  className?: string;
}

export const SvgImage = ({ name, width, height, className }: SvgImageProps) => (
  <img
    src={IMAGES[name]}
    width={width}
    height={height}
    className={className}
    alt="SVG"
    data-testid={`${svgImageTestID}-${name}`}
  />
);
