import { ValuationLog } from "components/app/Summary/ValuationLog";
import { Modal } from "components/shared";

import { IValuations } from "types";
import "../styles.scss";

export interface ModalRetailAmountLogProps {
  open: boolean;
  onClose: () => void;
  data?: IValuations["verification"];
}

export const ModalRetailAmountLog = ({
  open,
  onClose,
  data,
}: ModalRetailAmountLogProps) => {
  const retail = data?.retail;

  return (
    <Modal
      title="Retail Amount Log"
      open={open}
      size="auto"
      closeModal={onClose}
    >
      <div className="offer">
        <ValuationLog
          key={1}
          name={retail?.valuer?.name || ""}
          price={retail?.amount.toString() || ""}
          role=""
          date={retail?.created_at || ""}
          status="valued"
        />
      </div>
    </Modal>
  );
};
