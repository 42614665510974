export type INotification = {
  id: string;
  type: string;
  body: {
    message: string;
    title: string;
    type: string;
    contract_status?: {
      id: number;
      slug: string;
      name: string;
    };
    contract_id?: string;
    requester_id?: string;
    request_id?: string;
    tender_id?: string;
    dealership_id?: string;
    tender_vehicle_id?: string;
  };
  read_at: string;
  created_at: string;
  created_timestamp: string;
  event: string;
};

export interface INotificationResponseType {
  data: INotification[];
  meta: {
    last_page: number;
    current_page: number;
  };
}

export enum NOTIFICATION_TYPE {
  InspectionReceivedNotification = "InspectionReceivedNotification",
  OfferAmountAddedNotification = "OfferAmountAddedNotification",
  ValuationRequestNotification = "ValuationRequestNotification",
  TenderEndedNotification = "TenderEndedNotification",
  TenderStartedNotification = "TenderStartedNotification",
  OfferAcceptedNotification = "OfferAcceptedNotification",
  AcquisitionRevertedNotification = "AcquisitionRevertedNotification",
  OfferDeclinedNotification = "OfferDeclinedNotification",
  VehicleLostNotification = "VehicleLostNotification",
  TenderScheduledNotification = "TenderScheduledNotification",
  VehicleWonNotification = "VehicleWonNotification",
  ExternalValuationRequestNotification = "ExternalValuationRequestNotification",
  QuickSellAcceptedNotification = "QuickSellAcceptedNotification",
  QuickSellReceivedNotification = "QuickSellReceivedNotification",
  QuickSellUpdatedNotification = "QuickSellUpdatedNotification",
  AuctionWonNotification = "AuctionWonNotification",
  AuctionEndingNotification = "AuctionEndingNotification",
  AuctionEndedNotification = "AuctionEndedNotification",
  AuctionVehicleRemovedNotification = "AuctionVehicleRemovedNotification",
  WatchlistEndingNotification = "WatchlistEndingNotification",
  InternalValuationAddedNotification = "InternalValuationAddedNotification",
  AuctionOutbidNotification = "AuctionOutbidNotification",
  PPSRCompletedNotification = "PPSRCompletedNotification",
}

export const TENDER_NOTIFICATIONS = [
  NOTIFICATION_TYPE.TenderEndedNotification,
  NOTIFICATION_TYPE.VehicleWonNotification,
  NOTIFICATION_TYPE.TenderStartedNotification,
  NOTIFICATION_TYPE.TenderScheduledNotification,
];

export enum NOTIFICATION_FLOW_TYPES {
  INTERNAL = "internal-request-valuation",
  EXTERNAL = "external-request-valuation",
  DIRECT_OFFER = "direct-offer",
}

export enum NOTIFICATION_BODY_TYPE {
  BUYER_EXTERNAL_REQUEST = "buyer-external-valuation-request",
  SELLER_EXTERNAL_REQUEST = "seller-external-valuation-request",
  DEALERSHIP_ACQUISITION = "dealership-acquisition",
  DIRECT_OFFER = "direct-offer",
  PPSR_SEARCH_COMPLETED = "ppsr-search-completed",
}
