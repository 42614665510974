/*
 * API URLS should be named as a type followed by modifier eg: vehicleImage where vehicle is type and Image is modifier
 */
const URLS = {
  login: "/login",
  forgotPassword: "/password/forgot",
  resetPassword: "/password/reset",
  validate: "/validate",
  logout: "/logout",
  notifications: "/notifications",

  inspections: "/inspections",
  customerInspections: "/customer-inspections",
  customers: "/customers",
  comments: "/comments",

  negotiations: "negotiations",
  acquisitions: "/acquisitions",

  assessment: "/assessments",
  assessmentSections: "/assessments/sections",

  searchYears: "v1/search/years",
  searchMakes: "v1/search/makes",
  searchModels: "v1/search/models",
  searchVariants: "v1/search/variants",
  searchNVIC: "v1/search/details",
  searchPlate: "v1/plates/search",

  dealershipInspection: "/dealership-inspections",
  dealerships: "/dealerships",

  vehicles: "/vehicles",
  contract: "/contract",

  valuationRequests: "/valuation-requests",
  valuations: "/valuations",

  users: "/users",

  tenderSelling: "/tenders/selling",
  tenderBuying: "/tenders/buying",
  tenderBuyers: "/tenders/buyers",
  tenderVehiclesSelling: "/tenders/vehicles/selling",
  tenderVehiclesBuying: "/tenders/vehicles/buying",
  tenderBids: "/tenders/bids",

  motorMarketSelling: "/auctions/selling",
  motorMarketBuying: "/auctions/buying",
  motorMarketBids: "/auctions/bids",

  verification: "/verification",

  valuationCertificate: "/valuation-certificate",
  ppsr: "/ppsr",

  offers: "/offers",
  search: "/search",
};

export default URLS;
