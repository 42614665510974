import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import Ably from "ably";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ButtonLink, Modal, SvgImage } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { NotificationContext } from "context/NotificationContext";

import {
  ABLY_NOTIFICATIONS_EVENT,
  BUYING,
  getTenderURL,
  MOTOR_MARKET_AUCTION,
  MOTOR_MARKET_WON,
  SELLING,
  showErrorToast,
  StorageKeys,
} from "utils";

import {
  INotification,
  INotificationResponseType,
  NOTIFICATION_BODY_TYPE,
  NOTIFICATION_TYPE,
} from "types/INotification";

import { NotificationService } from "api/client";

import { useLocalStorage } from "hooks";

import { ModalNotification } from "../..";

export const ButtonNotification = () => {
  const { user, userID } = useContext(AuthContext);
  const { channel } = useContext(NotificationContext);
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useLocalStorage(
    StorageKeys.NotificationsUnreadState,
    false
  );
  const [lastTimeNotificationsChecked, setLastTimeNotificationsChecked] =
    useLocalStorage(StorageKeys.LastTimeNotificationsChecked, null);

  const {
    data,
    hasNextPage,
    refetch,
    fetchNextPage,
    isError,
    isInitialLoading,
    isFetching,
  } = useInfiniteQuery<INotificationResponseType, Error>(
    ["fetchNotifications"],
    {
      queryFn: NotificationService.getNotifications,
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.meta.current_page + 1;
        return lastPage.meta.last_page >= nextPage ? nextPage : undefined;
      },
    }
  );

  const { mutate } = useMutation(
    (id: string) => NotificationService.handleMarkReadNotification(id),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const notificationItems = useMemo(
    () => data?.pages?.map((page) => page.data).flat() || [],
    [data?.pages]
  );

  useEffect(() => {
    const unreadNotifications = notificationItems.find(
      (notification) => !notification.read_at
    );

    if (
      unreadNotifications &&
      (!lastTimeNotificationsChecked ||
        lastTimeNotificationsChecked < unreadNotifications.created_timestamp)
    ) {
      setHasUnreadNotifications(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (channel)
      channel.subscribe(
        ABLY_NOTIFICATIONS_EVENT,
        (ablyData: Ably.Types.Message) => {
          const contractID = ablyData?.data?.contract_id;
          if (contractID) {
            queryClient.invalidateQueries([`retail-summary-${contractID}`]);
            queryClient.invalidateQueries([`wholesale-summary-${contractID}`]);
            queryClient.invalidateQueries(["direct-offer-logs"]);
          }

          if (
            ablyData?.data?.notification_type ===
            NOTIFICATION_BODY_TYPE.PPSR_SEARCH_COMPLETED
          ) {
            queryClient.invalidateQueries([
              `${StorageKeys.PPSRList}-${userID}`,
            ]);
          }
          setHasUnreadNotifications(true);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  const handleScrollHitBottom = () => {
    fetchNextPage();
  };

  const handleShowNotifications = () => {
    refetch();
    setShowNotificationModal(true);
    setLastTimeNotificationsChecked(new Date().toISOString());
    setHasUnreadNotifications(false);
  };

  const handleReadNotification = async (item: INotification) => {
    mutate(item.id);
    setShowNotificationModal(false);

    try {
      const event = item.event;

      switch (event) {
        case NOTIFICATION_TYPE.AuctionEndingNotification: {
          return navigate(`/motor-market/my-listings`, {
            state: {
              filterStatus: "live",
            },
          });
        }
        case NOTIFICATION_TYPE.WatchlistEndingNotification: {
          return navigate(`/motor-market/buying`, {
            state: {
              filterStatus: "watchlist",
            },
          });
        }
        case NOTIFICATION_TYPE.AuctionEndedNotification: {
          return navigate(`/motor-market/my-listings`, {
            state: {
              filterStatus: "ended",
            },
          });
        }
        case NOTIFICATION_TYPE.AuctionVehicleRemovedNotification: {
          return navigate(`/motor-market/buying`);
        }

        case NOTIFICATION_TYPE.TenderEndedNotification:
        case NOTIFICATION_TYPE.TenderStartedNotification:
        case NOTIFICATION_TYPE.TenderScheduledNotification: {
          const tenderFlow =
            item.body.dealership_id === user?.data.dealership?.id
              ? SELLING.name
              : BUYING.name;
          return navigate(getTenderURL(tenderFlow, item.body.tender_id));
        }
        default:
          return navigate(`/summary/${item.body.contract_id}`);
      }
    } catch (err) {
      showErrorToast("You can't see the details of this notification");
      console.log("notification deeplinking error: ", err);
    }
  };

  return (
    <>
      <ButtonLink onClick={handleShowNotifications}>
        <div className="btn-notification">
          <SvgImage name="RingIcon" width={26} height={29} />
          {hasUnreadNotifications && (
            <span className="btn-notification__icon"></span>
          )}
        </div>
      </ButtonLink>
      <Modal
        size="auto"
        open={showNotificationModal}
        closeModal={() => setShowNotificationModal(false)}
      >
        <ModalNotification
          hasNextPage={hasNextPage}
          isLoading={isInitialLoading || isFetching}
          isError={isError}
          notifications={notificationItems}
          onMarkRead={handleReadNotification}
          onScrollHitBottom={handleScrollHitBottom}
        />
      </Modal>
    </>
  );
};
