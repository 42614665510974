import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

import { LoadItem } from "components/app";
import { customerInfoTestID } from "components/app/dataTestIDs";
import { FieldText, Button, TextError, Prompt } from "components/shared";
import { FieldPhone } from "components/shared/Fields/Text/FieldPhone";
import { TermsAndConditions } from "components/TermsAndConditions";

import {
  ERROR_MESSAGE,
  formatPhoneNumber,
  showErrorToast,
  showSuccessToast,
} from "utils";
import { pattern } from "utils/validation";

import { ICustomerDetail } from "types";

import { CustomerService, VehicleService } from "api/client";

export interface DetailsProps {
  id?: string;
  customerData?: ICustomerDetail;
  contractID?: string;
  dataTestID?: string;
  isLoading?: boolean;
  isError?: boolean;
  onSave: (customerID: string) => void;
}

type Error = {
  status: number;
  data: {
    errors: Array<string>;
  };
};

export const Details = ({
  contractID,
  customerData,
  dataTestID,
  isLoading,
  isError,
  onSave,
}: DetailsProps) => {
  const {
    register,
    setValue,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isDirty },
    control,
  } = useForm<ICustomerDetail>();

  useEffect(() => {
    if (customerData) {
      reset(customerData);
    }
  }, [customerData, reset]);

  const onError = (error: Error) => {
    if (error?.status === 422) {
      Object.keys(error?.data?.errors)?.forEach((errorField) => {
        setError(errorField as "first_name" | "last_name" | "email" | "phone", {
          type: "required",
        });
      });
    }
    showErrorToast("Failed to Save");
  };

  const { mutate: updateCustomer, isLoading: isLoadingUpdateCustomer } =
    useMutation(
      (payload: ICustomerDetail) =>
        CustomerService.updateDetails(payload.id, payload),
      {
        onSuccess: () => {
          reset({}, { keepDirty: false, keepValues: true });
          showSuccessToast("Saved successfully!");
        },
        onError: onError,
      }
    );

  const { mutate: createCustomer, isLoading: isLoadingCreateCustomer } =
    useMutation(
      (payload: ICustomerDetail & { contractID: string }) =>
        VehicleService.addOwner(payload, payload.contractID),
      {
        onSuccess: (data) => {
          toast.success("Saved successfully!", {
            autoClose: 2000,
            theme: "colored",
          });
          reset({}, { keepDirty: false, keepValues: true });
          onSave(data.data.id);
        },
        onError: onError,
      }
    );

  const onSaveCustomer = (data: ICustomerDetail) => {
    if (customerData?.id) {
      updateCustomer(data);
    } else {
      if (contractID) {
        createCustomer({ ...data, contractID });
      }
    }
  };

  const renderItems = () => {
    if (isLoading) {
      return <LoadItem />;
    }

    if (isError) {
      return <div className="text-sm text-center">{ERROR_MESSAGE}</div>;
    }

    return (
      <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-y-6 gap-x-10">
        <div>
          <FieldText
            title="First Name"
            type="text"
            {...register("first_name")}
            placeholder="Enter First Name"
          />
          {errors.first_name && <TextError error="Please enter first name" />}
        </div>
        <div>
          <FieldText
            title="Last Name"
            type="text"
            {...register("last_name")}
            placeholder="Enter Last Name"
          />
          {errors.last_name && <TextError error="Please enter last name" />}
        </div>
        <div>
          <FieldText
            title="Email Address"
            type="text"
            {...register("email", {
              pattern: pattern.email,
            })}
            placeholder="Enter Email Address"
          />
          {errors.email && (
            <TextError error="Please enter a valid email address" />
          )}
        </div>
        <div>
          <FieldPhone register={register} setValue={setValue} name="phone" />
          {errors.phone && (
            <TextError error="Please enter the correct phone number" />
          )}
        </div>
        <FieldText
          title="Company Name"
          type="text"
          {...register("company_name", { required: false })}
          placeholder="Enter their company name"
        />
        <Controller
          control={control}
          render={() => (
            <FieldText
              {...register("nar_expiry")}
              type="date"
              title="NAR Expiry"
            />
          )}
          name="nar_expiry"
        />
      </div>
    );
  };

  return (
    <div
      className="rounded-xl w-full flex flex-col gap-4 mt-10"
      data-testid={dataTestID || customerInfoTestID}
    >
      {renderItems()}
      <div className="mt-5">
        <TermsAndConditions />
      </div>
      <div className="w-full flex items-center mt-1 mb-4">
        <div className="w-90 ml-2">
          <Button
            type="button"
            isLoading={isLoadingUpdateCustomer || isLoadingCreateCustomer}
            onClick={handleSubmit(onSaveCustomer)}
            disabled={!isDirty}
            className="shadow-md"
          >
            <span className="uppercase text-sm font-bold">Save</span>
          </Button>
        </div>
      </div>
      <Prompt isDirty={isDirty} />
    </div>
  );
};
