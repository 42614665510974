import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useContext, useRef, useState, useMemo } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { ModalCarouselCar } from "components/app";
import { PhotosSkeleton } from "components/PhotosSkeleton";
import { Button, Prompt } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { SearchContext } from "context/SearchContext";

import { ERROR_MESSAGE, showErrorToast, showSuccessToast } from "utils";

import { IImage, ISection, IVehicleSummary, OptimisticImage } from "types";

import { InspectionService, VehicleService } from "api/client";

import { useOnDrag, useRenderImages } from "hooks";

import { VehiclePhoto } from "./VehiclePhoto/VehiclePhoto";

interface VehiclePhotoContainerProps {
  data: IVehicleSummary;
  refetch?: () => void;
}

export const VehiclePhotoContainer = ({
  data,
  refetch,
}: VehiclePhotoContainerProps) => {
  const { userID } = useContext(AuthContext);

  const { setIsUpdated } = useContext(SearchContext);
  const previousActiveImages = useRef<IImage[]>([]);
  const [isCarousel, setIsCarousel] = useState(false);
  const [activeCarouselItem, setActiveCarouselItem] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activeImages, setActiveImages] = useState<IImage[]>([]);
  const [hasOrderChanged, setHasOrderChanged] = useState(false);
  const [activeDragAndDrop, setActiveDragAndDrop] = useState({
    from: 0,
    to: 0,
  });
  const [optimisticImages, setOptimisticImages] = useState<OptimisticImage[]>(
    []
  );
  const [deletedImage, setDeletedImage] = useState("");

  const { is_locked, contract_id } = data || {};
  const activeImagesID = activeImages.map((image) => image.id);

  const { data: sectionData } = useQuery<{ data: ISection[] }, Error>(
    [`fetchSection_${userID}`],
    InspectionService.getSections
  );

  const { mutate: sortImages, isLoading: isLoadingSortImages } = useMutation(
    () => VehicleService.sortImages({ image_ids: activeImagesID }, contract_id),
    {
      onSuccess: () => {
        showSuccessToast("Saved Successfully");
        setHasOrderChanged(false);
      },
      onError: () => {
        showErrorToast("Failed to Save");
      },
    }
  );

  useEffect(() => {
    setHasOrderChanged(
      activeImages.some(
        (image, index) => image.id !== previousActiveImages.current[index]?.id
      )
    );
  }, [activeImages]);

  const {
    data: images,
    refetch: refetchImages,
    isLoading: isLoadingImages,
    isError: isErrorImages,
    isSuccess: isSuccessImages,
  } = useQuery<{ data: IImage[] }, Error>(
    [`fetchvehicleimages_${contract_id}_${userID}`, contract_id],
    VehicleService.getImages,
    {
      onSuccess: () => {
        setOptimisticImages([]);
        setDeletedImage("");
      },
      enabled: !!contract_id,
    }
  );

  const { renderedImages, availablePlaceholders } = useRenderImages({
    images,
    activeDragAndDrop,
    activeImages,
    setActiveDragAndDrop,
    setActiveImages,
    sectionData,
    optimisticAddImages: optimisticImages,
    deletedImage,
  });

  const onRefresh = () => {
    refetchImages();
    setIsUpdated(contract_id, true);
    setActiveImages([]);
    refetch?.();
  };

  const { onDragEnd } = useOnDrag({
    activeImages,
    images,
    onActiveDragAndDrop: setActiveDragAndDrop,
    validLocationID: "additional",
  });

  const onCarousel = (activeIndex: number) => {
    setIsCarousel(true);
    setActiveCarouselItem(activeIndex);
  };

  const onDeleteImage = (id: string) => {
    setActiveImages((prev) => prev.filter((image) => image.id !== id));
    setDeletedImage(id);
  };

  const activeCarouselImages = activeImages?.length
    ? activeImages
    : images?.data || [];

  const renderPhotos = () => {
    if (isLoadingImages) {
      return <PhotosSkeleton />;
    }

    if (isErrorImages) {
      return <div className="text-sm text-center">{ERROR_MESSAGE}</div>;
    }

    if (isSuccessImages) {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          {renderedImages?.map((section, index) => (
            <Droppable droppableId={section.key} key={section.key}>
              {(provided) => (
                <div
                  className="flex flex-wrap"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <div
                    className="bg-white w-36 box-border border-2 border-lightDustBlue border-solid rounded-lg shadow-photo hover:cursor-pointer"
                    key={index}
                    ref={provided.innerRef}
                  >
                    <VehiclePhoto
                      contractID={data.contract_id}
                      images={activeCarouselImages}
                      onRefresh={onRefresh}
                      onCarousel={onCarousel}
                      isLocked={is_locked}
                      section={section}
                      onChange={(isUploading: boolean) => {
                        setIsLoading(isUploading);
                      }}
                      placeholders={availablePlaceholders}
                      onDeleteImage={onDeleteImage}
                      addOptimisticImages={setOptimisticImages}
                    />
                  </div>
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      );
    }

    return null;
  };

  return (
    <div className="rounded-xl w-full h-fit p-6 flex flex-col gap-4 shadow-sm bg-sidebarbg mb-2">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <span className="text-xl font-semibold">Photos</span>
          {!is_locked && (
            <div className="flex flex-col mt-[8px]">
              <span className="text-[10px] text-label">
                &#x2022; upload Up to 9 .jpg and .png photos per time
              </span>
              <span className="text-[10px] text-label">
                &#x2022; hold and drag to reorder photos.
              </span>
            </div>
          )}
        </div>
        {hasOrderChanged && (
          <div className="w-[117px] h-[41px]">
            <Button
              type="button"
              onClick={sortImages}
              isLoading={isLoadingSortImages}
            >
              <span className="text-sm font-bold">SAVE</span>
            </Button>
          </div>
        )}
      </div>
      <div className="flex justify-items-center flex-wrap gap-3.5 bg-sidebarbg rounded-10 max-h-400 md:max-h-450 lg:max-h-500 overflow-auto">
        {renderPhotos()}
      </div>
      <ModalCarouselCar
        open={isCarousel}
        images={activeCarouselImages}
        activeIndex={activeCarouselItem}
        closeModal={() => setIsCarousel(false)}
        contractID={contract_id}
        onRefresh={refetchImages}
      />
      <Prompt isDirty={hasOrderChanged} />
    </div>
  );
};
